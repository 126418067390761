<template>
<div>
    <v-row>
        <v-col v-for="(item,index) in items" :key="index" cols="12">
            <v-card outlined >
                <v-row class="text-center mt-1" justify="center" align="center">
                    <v-col>
                        <h3>جمهورية العراق</h3>
                        <h3>مجلس النواب</h3>
                        <h3>الأمن</h3>
                    </v-col>
                    <v-col>
                        <img height="140" style="border-radius: 30px" src="../../assets/images/parliament.jpeg" />
                    </v-col>
                    <v-col>
                        <h3>كومارى عيراق</h3>
                        <h3>ئه نجومه نى نوينه ران</h3>
                        <h3>ئاسايش</h3>
                    </v-col>

                </v-row>
                <v-row class="mx-5">
                    <v-col cols="6">
                        <h3>الجهة الطالبة للتخويل: ( {{item.permit.organization.name}} )</h3>
                    </v-col>
                    <v-col cols="6" dir="ltr">
                        <h3>رقم التخويل:  {{item.permit.sepuence}} </h3>
                        <div class="grey--text">
                            <h3 >صلاحية التخويل:
                                {{ new Date(item.permit.expireAt).toLocaleDateString("sv").split("T")[0] }}
                            </h3>
                        </div>
                    </v-col>

                </v-row>
                <v-divider inset class="mx-auto mt-2" />
                <v-row class="ma-3">
                    <v-col cols="3">
                        <qr-code :text="item.id" :size="150" color="black" bg-color="white" error-level="L" class="mb-4"></qr-code>
                    </v-col>
                    <v-col cols="8">
                        <h3 class="mr-4 mb-2">الإسم الثلاثي: ( {{item.fullName}} )</h3>
                        <h3 class="mr-4 mb-2">الجهة الزائرة: ( {{item.visiting}} )</h3>
                        <h3 class="mr-4 mb-2">الأجهزة: ( {{item.devices}} )</h3>
                        <h3 class="mr-4 mb-2">نوع العجلة: ( {{item.carName}} ) - لون العجلة: ( {{item.carColor}} ) - موديل العجلة: ( {{item.carYear}} )
                        </h3>
                    </v-col>

                </v-row>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    data() {
        return {
            items: []
        }
    },

    // computed: {
    //     items() {
    //         return JSON.parse(sessionStorage.getItem("printPermit"));
    //     }
    // },

    async created() {
        this.items = await JSON.parse(sessionStorage.getItem("printPermit"))

        setTimeout(() => window.print(), 1000)
    }
}
</script>
